<template>
  <img
    :src="makeSrc()"
    class="google-maps-image"
  >
</template>
<script>
export default {
  name: 'GMapImage',
  props: {
    latlng: {
      type: String,
      default: null
    },
    markers: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: 'blue'
    },
    size: {
      type: Array,
      default: () => [250, 250]
    },
    zoom: {
      type: Number,
      default: 12
    }
  },
  data () {
    return {
      colors: [
        'green',
        'blue',
        'red'
      ]
    }
  },
  computed: {
    marker () {
      let returns;
      if (this.latlng) {
        returns = `markers=color:${this.color}%7C${this.latlng}`;
      } else {
        returns = [];
        this.markers.forEach((location, index) => {
          returns.push(`markers=color:${this.colors[index]}|${location}`)
        })
        returns = returns.join('&');
      }
      return returns;
    }
  },
  methods: {
    makeSrc () {
      const params = [
          `zoom=${this.zoom}`,
          `size=${this.size[0]}x${this.size[1]}`,
          `${this.marker}`,
          `key=${process.env.VUE_APP_GKEY}`
        ].join('&');

      return `https://maps.googleapis.com/maps/api/staticmap?${params}`;
    }
  }
}
</script>
